<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="900"
    :retain-focus="false"
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <BaseButton
        v-bind="attrs"
        v-on="on"
        outlined
        color="primary"
        title="Visualizar documentos"
      />
    </template>

    <v-form ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            Faça o upload de documentos necessários ou faltantes.
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-file-input
                label="Arquivo"
                dense
                outlined
                required
                :rules="[required]"
                v-model="document.file"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDocumentType
                label="Tipo de arquivo"
                required
                :rules="[required]"
                v-model="document.documentType"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="2">
              <BaseButton
                color="secondary"
                :disabled="!valid"
                title="Adicionar"
                height="40"
                @click="uploadDocument"
              />
            </v-col>
          </v-row>

          <BaseTableList dense title="Listagem de documentos">
            <template v-slot:table>
              <v-data-table
                dense
                disable-sort
                item-key="id"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalRecords"
                :items-per-page="10"
                :footer-props="{
                  itemsPerPageOptions: [10, 20, 50, 100],
                  showFirstLastPage: true,
                  firstIcon: 'first_page',
                  lastIcon: 'last_page',
                  prevIcon: 'chevron_left',
                  nextIcon: 'chevron_right'
                }"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="success"
                        class="mr-2"
                        small
                        @click="download(item)"
                      >
                        fa-solid fa-download
                      </v-icon>
                    </template>
                    <span>Baixar arquivo</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="error"
                        small
                        @click="deleteDocument(item)"
                      >
                        fa-regular fa-trash-can
                      </v-icon>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </template>
          </BaseTableList>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  props: {
    employeeId: {
      type: String,
      require: true
    },
    dependentId: {
      type: String
    },
    contractId: {
      type: String
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    dialog: false,
    valid: true,
    options: {},
    headers: [
      { text: 'Documento', value: 'documentType' },
      { text: 'Data de envio', value: 'createdAt' },
      { text: 'Vida', value: 'lifeName' },
      { text: '', value: 'action' }
    ],
    document: {
      file: null,
      employeeOrDependentId: null,
      documentType: null
    }
  }),

  methods: {
    formatDate,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const movementService = new MovementService();
        const { status, data } =
          await movementService.getPagedEmployeeDocuments({
            page,
            take: itemsPerPage,
            employeeId: this.employeeId,
            contractId: this.contractId
          });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async uploadDocument() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const params = Object.assign({}, this.document);
        params.employeeId = this.employeeId;
        params.employeeOrDependentId = this.dependentId
          ? this.dependentId
          : this.employeeId;
        params.contractId = this.contractId;

        const movementService = new MovementService();
        const { status } = await movementService.uploadEmployeeDocument(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async download(item) {
      try {
        const params = Object.assign({}, item);
        params.employeeId = this.employeeId;

        const movementService = new MovementService();
        const { status, data } = await movementService.downloadDocument(params);

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async deleteDocument(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const params = Object.assign({}, item);
          params.employeeId = this.employeeId;

          const movementService = new MovementService();
          const { status } = await movementService.deleteDocument(params);

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
